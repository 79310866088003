import {
  APICallPayloads,
  FetchCreateSessionParams,
  FetchCreateSessionResponse,
  FetchDeleteSessionParams,
  FetchDeleteSessionResponse,
  FetchGetAPIUsageByProjectIdParams,
  FetchGetAPIUsageByProjectIdResponse,
  FetchGetUploadHistoryParams,
  FetchGetUploadHistoryResponse,
  FetchGetUploadSessionParams,
  FetchGetUploadSessionResponse,
  FetchReadStatusParams,
  FetchReadStatusResponse,
  FetchUpdateSessionParams,
  FetchUpdateSessionResponse,
  FetchUploadChunkParams,
  FetchUploadChunkResponse,
} from "~/client/types";

import getConfigs from "~/client/lib/getConfigs";
import { makeAPIRequest } from "~/client/lib/api";

const { REACT_APP_SENSE_API_API_BASE_URL: senseApiApiBaseURL } = getConfigs();

const API_URL_PREFIX = senseApiApiBaseURL ? `${senseApiApiBaseURL}` : "";

export const fetchGetAPIUsageByProjectId = ({
  config,
  params,
}: APICallPayloads<FetchGetAPIUsageByProjectIdParams>): Promise<FetchGetAPIUsageByProjectIdResponse> => {
  const { projectId, from, to } = params;
  let url = `${API_URL_PREFIX}/projects/${projectId}/usage?&`;

  if (from) {
    url += `from=${encodeURIComponent(from)}&`;
  }
  if (to) {
    url += `to=${encodeURIComponent(to)}&`;
  }

  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchCreateSession = ({
  config,
  params,
}: APICallPayloads<FetchCreateSessionParams>): Promise<FetchCreateSessionResponse> => {
  const url = `${API_URL_PREFIX}/audio_sessions/`;
  return makeAPIRequest({
    ...config,
    url,
    method: "POST",
    data: params,
  });
};

export const fetchUpdateSession = ({
  config,
  params,
}: APICallPayloads<FetchUpdateSessionParams>): Promise<FetchUpdateSessionResponse> => {
  const { sessionId, readOnly } = params;
  const url = `${API_URL_PREFIX}/audio_sessions/${sessionId}`;
  return makeAPIRequest({
    ...config,
    url,
    method: "PATCH",
    data: {
      ...(readOnly && { make_readonly: readOnly }),
    },
  });
};

export const fetchDeleteSession = ({
  config,
  params,
}: APICallPayloads<FetchDeleteSessionParams>): Promise<FetchDeleteSessionResponse> => {
  const { sessionId } = params;
  const url = `${API_URL_PREFIX}/audio_sessions/${sessionId}`;
  return makeAPIRequest({
    ...config,
    url,
    method: "DELETE",
  });
};

export const fetchUploadChunk = ({
  config,
  params,
}: APICallPayloads<FetchUploadChunkParams>): Promise<FetchUploadChunkResponse> => {
  const { sessionId, chunkSequence, chunkData } = params;
  const url = `${API_URL_PREFIX}/audio_sessions/${sessionId}/chunks/${chunkSequence}`;
  return makeAPIRequest({
    ...config,
    url,
    method: "PUT",
    data: {
      data: chunkData,
    },
  });
};

export const fetchReadStatus = ({
  config,
  params,
}: APICallPayloads<FetchReadStatusParams>): Promise<FetchReadStatusResponse> => {
  const { sessionId, offset, limit } = params;
  let url = `${API_URL_PREFIX}/audio_sessions/${sessionId}/results?`;
  if (offset) {
    url += `offset=${encodeURIComponent(offset)}&`;
  }
  if (limit) {
    url += `limit=${encodeURIComponent(limit)}&`;
  }

  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

// drag & drop
export const fetchGetUploadHistory = ({
  params,
  config,
}: APICallPayloads<FetchGetUploadHistoryParams>): Promise<FetchGetUploadHistoryResponse> => {
  const url = `${API_URL_PREFIX}/projects/${params.project_id}/sessions`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchGetUploadSession = ({
  params,
  config,
}: APICallPayloads<FetchGetUploadSessionParams>): Promise<FetchGetUploadSessionResponse> => {
  const { project_id, session_id } = params;
  const url = `${API_URL_PREFIX}/projects/${project_id}/sessions/${session_id}`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

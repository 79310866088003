import { ComponentType } from "react";
import { RouteComponentProps } from "react-router-dom";

export enum PAGE_TITLE {
  INDEX = "Dashboard",
  PROJECT = "Projects",
  PROJECT_NEW = "New Project",
  PROJECT_DETAIL = "Project Overview",
  PROJECT_DETAIL_SENSE_SDK = "Edge SDK",
  PROJECT_DETAIL_ANALYTICS = "Analytics",
  PROJECT_DETAIL_ANALYTICS_OLD = "History",
  PROJECT_DETAIL_POST_ACTION = "Post Actions",
  PROJECT_DETAIL_UPLOAD = "Upload",
  PROJECT_DETAIL_SPEAKER_RECOGNITION = "Speaker Recognition",
  PROJECT_DETAIL_SETTINGS = "Project Settings",
  PROJECT_DETAIL_SETTINGS_GENERAL = "Project Settings General",
  PROJECT_DETAIL_SETTINGS_ADVANCED = "Project Settings Advanced",
  USAGE = "Usage",
  USAGE_API = "Cloud API Usage",
  USAGE_SDK = "Edge SDK Usage",
  BILLING = "Billing",
  BILLING_INFO = "Information",
  BILLING_INVOICES = "Invoices",
  ORGANIZATION = "Organization",
  ORGANIZATION_MY_MEMBERS = "My members",
  ORGANIZATION_INVITED = "Joined",
  NOTFOUND = "Not Found",
}

export enum PAGE_ROUTE_PATH_NAME {
  INDEX = "/",
  PROJECT = "/project",
  PROJECT_NEW = "/new",
  PROJECT_DETAIL = "/project/:projectId",
  PROJECT_DETAIL_SENSE_SDK = "/project/:projectId/sdk",
  PROJECT_DETAIL_ANALYTICS = "/project/:projectId/analytics",
  PROJECT_DETAIL_ANALYTICS_OLD = "/project/:projectId/previous_analytics",
  PROJECT_DETAIL_POST_ACTION = "/project/:projectId/post_action",
  PROJECT_DETAIL_UPLOAD = "/project/:projectId/upload",
  PROJECT_DETAIL_SPEAKER_RECOGNITION = "/project/:projectId/speaker_recognition",
  PROJECT_DETAIL_SETTINGS = "/project/:projectId/settings",
  PROJECT_DETAIL_SETTINGS_GENERAL = "/project/:projectId/settings/general",
  PROJECT_DETAIL_SETTINGS_ADVANCED = "/project/:projectId/settings/advanced",
  USAGE = "/usage",
  USAGE_API = "/usage/api",
  USAGE_SDK = "/usage/sdk",
  BILLING = "/billing",
  BILLING_INFO = "/billing/information",
  BILLING_INVOICES = "/billing/invoices",
  ORGANIZATION = "/organization",
  ORGANIZATION_MY_MEMBERS = "/organization/members",
  ORGANIZATION_INVITED = "/organization/joined",
  NOT_FOUND = "*",
}

export interface PageRoute {
  title: PAGE_TITLE | "";
  pathname?: PAGE_ROUTE_PATH_NAME;
  exact?: boolean;
  component?: ComponentType<RouteComponentProps<{ projectId?: string }>>;
}

export interface SubTab {
  to: string;
  label: string;
  selected?: boolean;
  disabled?: boolean;
  isBETA?: boolean;
}

import { MouseEventHandler, PropsWithChildren } from "react";

import { BaseStyleProps } from "../types";
import { ToastCloseIcon } from "../Icons";
import { media } from "@cochlearai/util";
import styled from "styled-components";

const Background = styled.div`
  background: linear-gradient(90deg, #edf0ff 52.19%, #4b68ff 127.97%);
  color: ${(p) => p.theme.colors.blue[60]};
`;

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${media.size.lg};
  padding: 16px 0;
  padding-left: unset;
  padding-right: unset;

  ${media.query.xl} {
    width: ${media.size.lg};
    padding-left: unset;
    padding-right: unset;
  }

  ${media.query.lg} {
    width: calc(100vw);
    padding-left: 60px;
    padding-right: 60px;
  }

  ${media.query.md} {
    width: calc(100vw);
    padding-left: 25px;
    padding-right: 25px;
  }

  ${media.query.sm} {
    width: calc(100vw);
    padding-left: 25px;
    padding-right: 25px;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export interface BannerProps extends BaseStyleProps {
  onClose?: MouseEventHandler<SVGSVGElement>;
}

/**
 * React component used to create a Banner layout.
 * @param className  - The className of the banner.
 * @param style      - The style of the banner.
 * @param onClose    - The onClose handler of the banner.
 * @see Docs https://dashboardstorybook.cochl.ai/?path=/story/banner--default
 */
export const Banner = ({
  children,
  className,
  style,
  onClose,
}: PropsWithChildren<BannerProps>) => {
  return (
    <Background>
      <Container className={className} style={style}>
        <Content>{children}</Content>
        <div>
          <ToastCloseIcon
            onClick={onClose}
            fill="white"
            style={{ cursor: "pointer" }}
          />
        </div>
      </Container>
    </Background>
  );
};

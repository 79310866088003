import { Button, Chip, InfoTooltip, Typo } from "@cochlearai/ui";
import { FC, useMemo, useState } from "react";
import { FileInfo, PlanType, Subscription, TotalUsage } from "~/client/types";
import { bytesToSize, getUploadPriceInfo } from "~/client/lib";
import styled, { useTheme } from "styled-components";

import { media } from "@cochlearai/util";

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Column = styled(Flex)`
  width: 100%;
  flex-direction: column;
`;

const ResponsiveFlex = styled(Flex)`
  ${media.query.md} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TextDivider = styled.div`
  width: 1px;
  height: 20px;
  background: ${(p) => p.theme.colors.grey[30]};
  margin: 0 10px;
`;

const Divider = styled.div`
  width: 2px;
  height: 100px;
  background: ${(p) => p.theme.colors.grey[10]};
  border-radius: 30px;
  margin-left: 60px;
  margin-right: 100px;

  ${media.query.md} {
    width: 100%;
    height: 2px;
    margin: 20px 0;
  }
`;

const CancelBtn = styled(Typo)`
  cursor: pointer;
  margin-right: 23px;
  color: ${(p) => p.theme.colors.blue[60]};
`;

const TotalPrice = styled(Typo)<{ isDiscounted?: boolean }>`
  color: ${(p) =>
    p.isDiscounted ? p.theme.colors.grey[40] : p.theme.colors.blue[60]};
  text-decoration: ${(p) => (p.isDiscounted ? "line-through" : "")};
  text-decoration-color: ${(p) => p.theme.colors.blue[60]};
  text-decoration-thickness: 1px;
`;

const TypeChip = styled(Chip)`
  color: ${(p) => p.theme.colors.blue[60]};
  margin-left: 8px;
`;

interface Props {
  fileInfo?: FileInfo;
  fileLength?: number;
  usage?: TotalUsage;
  subscription: Subscription;
  withTranscript: boolean;
  analyze: () => void;
  onClickCancel?: () => void;
  onLinkToUpgrade?: () => void;
}

const UploadInfo: FC<Props> = ({
  fileInfo,
  fileLength = 0,
  usage,
  subscription,
  withTranscript,
  analyze,
  onClickCancel,
  onLinkToUpgrade,
}) => {
  const { colors } = useTheme();
  const [priceInfoPopoverIsOpen, setPriceInfoPopoverOpen] =
    useState<boolean>(false);
  const priceInfo = useMemo(
    () =>
      getUploadPriceInfo({
        usage: usage?.api.total ?? 0,
        fileLength,
        subscription,
      }),
    [fileLength, subscription, usage?.api.total],
  );
  const isFreeUser = subscription?.plan_id === PlanType.FREE;

  return (
    <ResponsiveFlex style={{ width: "100%" }}>
      <Column>
        <Flex
          style={{
            justifyContent: "space-between",
            marginBottom: "20px",
            width: "100%",
          }}
        >
          <ResponsiveFlex>
            <Typo
              variant="button"
              style={{
                color: colors.grey[70],
                marginRight: "10px",
                maxWidth: "464px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {fileInfo?.name}
            </Typo>
            <Flex>
              <Typo variant="caption1" style={{ color: colors.grey[70] }}>
                {bytesToSize(fileInfo?.size)}
              </Typo>
              <Typo
                variant="caption1"
                style={{ marginLeft: "10px", color: colors.grey[30] }}
              >
                |
              </Typo>
              <Typo
                variant="caption1"
                style={{ marginLeft: "10px", color: colors.grey[70] }}
              >
                {priceInfo.fileLengthText}
              </Typo>
            </Flex>
          </ResponsiveFlex>
        </Flex>
        <Flex
          style={{
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          {isFreeUser && priceInfo.isOverUsed ? (
            <Typo variant="h4" style={{ color: colors.red }}>
              {priceInfo.description}
            </Typo>
          ) : (
            <>
              <Typo variant="h4">{priceInfo.fileLengthText}</Typo>
              <TextDivider />
              <TotalPrice
                variant="h3"
                isDiscounted={!!priceInfo.discountedPrice}
              >
                {priceInfo.originalPrice}
              </TotalPrice>
              {priceInfo.discountedPrice && (
                <Typo
                  variant="h3"
                  style={{ color: colors.blue[60], marginLeft: "8px" }}
                >
                  {priceInfo.discountedPrice}
                </Typo>
              )}
              <InfoTooltip
                open={priceInfoPopoverIsOpen}
                text={priceInfo.description}
                onMouseOver={() => setPriceInfoPopoverOpen(true)}
                onMouseOut={() => setPriceInfoPopoverOpen(false)}
                position="bottom"
                style={{ marginLeft: "8px" }}
              />
              <TypeChip>Cochl.Sense API</TypeChip>
              {withTranscript && <TypeChip>Transcription (FREE)</TypeChip>}
            </>
          )}
        </Flex>
      </Column>
      <Divider />
      <Flex>
        <CancelBtn variant="button" onClick={onClickCancel}>
          Cancel
        </CancelBtn>
        <Button
          color="primary"
          style={{
            padding: "11px 16px",
            width: "130px",
          }}
          onClick={
            isFreeUser && priceInfo.isOverUsed ? onLinkToUpgrade : analyze
          }
        >
          {isFreeUser && priceInfo.isOverUsed
            ? "Upgrade plan"
            : "Start analyzing"}
        </Button>
      </Flex>
    </ResponsiveFlex>
  );
};

export default UploadInfo;

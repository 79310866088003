import {
  Button,
  Checkbox,
  CloudAPIIcon,
  EdgeSDKIcon,
  Input,
  Modal,
  ModalCloseIcon,
  Typo,
} from "@cochlearai/ui";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  PRODUCT_TYPE_UI_LABELS,
  PROJECT_NAME_LIMIT,
  SENSE_TAGS_BY_CATEGORY,
} from "~/client/lib/constants";
import styled, { useTheme } from "styled-components";
import {
  useCreateProject,
  useProjectList,
  useSdkTags,
  useSubscription,
} from "~/client/hooks";

import { ProjectType } from "~/client/types/project";
import { SenseCategory } from "~/client/types";
import { TagCategory } from "~/client/components";
import { TypeSelectButton } from "../TypeSelectButton";
import { media } from "@cochlearai/util";
import { numberWithCommas } from "~/client/lib/helpers";
import { useTranslation } from "react-i18next";

const Flex = styled.div`
  display: flex;
`;

const ModalContainer = styled(Modal)`
  padding: 50px;
  width: auto;
  max-width: 1200px;
  display: flex;
  flex-direction: column;

  ${media.query.lg} {
    width: 100%;
    max-width: auto;
  }
`;

const Inner = styled(Flex)`
  display: flex;

  ${media.query.md} {
    flex-direction: column;
  }
`;

const SDKTagContainer = styled(Flex)`
  flex-direction: column;
  width: calc(50% - 10px);
  margin-top: 40px;

  ${media.query.md} {
    width: 100%;
  }
`;

const SDKTagInfo = styled(Flex)`
  align-items: center;
  justify-content: space-between;

  ${media.query.sm} {
    flex-direction: column;
    align-items: start;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  ${media.query.md} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const InputWithWarning = styled.div`
  display: flex;
  flex-direction: column;
`;

const CochlSenseProjectInput = styled(Input)`
  margin-top: 10px;
`;

const TagsCountBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: ${(p) => p.theme.colors.black};
  color: ${(p) => p.theme.colors.white};
  margin-left: 8px;
`;

const ProjectTypeWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 8px;

  ${media.query.md} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Divider = styled.div`
  width: 1px;
  background-color: ${(p) => p.theme.colors.grey[30]};
  margin: 0 7px;
`;

const TagsPrice = styled(Typo)`
  color: ${(p) => p.theme.colors.grey[80]};
`;

const CloudAPIStyledIcon = styled(CloudAPIIcon)<{ selected: boolean }>`
  margin-right: 8px;
  fill: ${(p) =>
    p.selected ? p.theme.colors.blue[60] : p.theme.colors.grey[80]};
`;

const EdgeSDKStyledIcon = styled(EdgeSDKIcon)<{ selected: boolean }>`
  margin-right: 8px;
  fill: ${(p) =>
    p.selected ? p.theme.colors.blue[60] : p.theme.colors.grey[80]};
`;

const ConfirmButton = styled(Button)<{ warning?: boolean }>`
  min-height: 50px;
  margin-top: 30px;
  background-color: ${(p) =>
    p.warning ? p.theme.colors.red : p.theme.colors.blue[60]};
`;

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
}

const ProjectCreateModal: FC<Props> = ({ open, onClose, onConfirm }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const {
    create,
    name,
    setName,
    validation,
    setValidation,
    projectType,
    setProjectType,
    tags,
    setTags,
    tagPrice,
  } = useCreateProject();
  const { selectedProjectTab } = useProjectList();
  const { currentSubscription } = useSubscription();
  const { product_types } = currentSubscription;
  const { sdkTags = [] } = useSdkTags();
  const { CLOUD_API, EDGE_SDK } = ProjectType;
  const isAvailableSDK = product_types.includes(EDGE_SDK);
  const isAvailableAPI = product_types.includes(CLOUD_API);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [selectedTagCategory, setSelectedTagCategory] = useState<SenseCategory>(
    SenseCategory.ALL,
  );
  const categoryTags = useMemo(
    () =>
      sdkTags.filter(
        (item) =>
          SENSE_TAGS_BY_CATEGORY[selectedTagCategory].indexOf(item.value) !==
          -1,
      ),
    [sdkTags, selectedTagCategory],
  );

  const onClickConfirm = useCallback(async () => {
    await create();
    if (validation.validated) {
      if (onConfirm) {
        onConfirm();
      }
    }
    setTags([]);
  }, [create, onConfirm, setTags, validation.validated]);

  const selectProjectType = useCallback(
    (type) => {
      if (product_types.includes(type) && type !== projectType) {
        setProjectType(type);
        if (type === CLOUD_API) {
          setTags([]);
        }
      }
    },
    [product_types, projectType, setProjectType, setTags, CLOUD_API],
  );

  const onCloseHandler = useCallback(() => {
    onClose && onClose();
    setTags([]);
    setSelectedTagCategory(SenseCategory.ALL);
  }, [onClose, setTags]);

  const onChangeInput = useCallback(
    (e) => {
      const value = e.target.value;

      if (value.length > PROJECT_NAME_LIMIT) {
        setValidation({
          validating: name.length > 0,
          validated: false,
          error: `Input at least 1 characters, with a maximum of ${PROJECT_NAME_LIMIT} characters`,
        });
      }

      setName(
        value.length > PROJECT_NAME_LIMIT
          ? value.slice(0, PROJECT_NAME_LIMIT)
          : value,
      );
    },
    [name.length, setName, setValidation],
  );

  const onClickTags = useCallback(
    (item) => {
      if (!tags.some(({ value }) => value === item.value)) {
        setTags([...tags, ...[item]]);
      } else {
        setTags(tags.filter((data) => data !== item));
      }
    },
    [tags, setTags],
  );

  const onClickSelectAll = useCallback(
    (e) => {
      setTags(
        e.target.checked
          ? tags.concat(
              categoryTags.filter(
                (item) => !tags.some(({ value }) => value === item.value),
              ),
            )
          : tags.filter(
              (item) =>
                SENSE_TAGS_BY_CATEGORY[selectedTagCategory].indexOf(
                  item.value,
                ) === -1,
            ),
      );
    },
    [categoryTags, selectedTagCategory, setTags, tags],
  );

  useEffect(() => {
    if (!open) {
      setName("");
      setProjectType(
        selectedProjectTab === ProjectType.EDGE_SDK &&
          product_types.includes(selectedProjectTab)
          ? ProjectType.EDGE_SDK
          : ProjectType.CLOUD_API,
      );
    }
  }, [open, product_types, selectedProjectTab, setName, setProjectType]);

  useEffect(() => {
    let isNotSelected = false,
      i = 0;
    do {
      if (!tags.some(({ value }) => value === categoryTags[i]?.value)) {
        isNotSelected = true;
      }
      i++;
    } while (!isNotSelected && i < categoryTags.length);

    setIsSelectAll(!isNotSelected);
  }, [categoryTags, tags]);

  return (
    <ModalContainer open={open} onClose={onCloseHandler}>
      <ModalCloseIcon
        data-testid="modal-close"
        style={{
          position: "absolute",
          right: "50px",
          top: "50px",
          cursor: "pointer",
        }}
        fill={colors.black}
        onClick={onCloseHandler}
      />
      <Inner>
        <Flex style={{ flexDirection: "column", width: "100%" }}>
          <Typo variant="h1">
            {t("Let's try Cochl.Sense", { ns: "common" })}
          </Typo>
          <Typo
            variant="h5"
            style={{ margin: "14px 0 30px 0", color: colors.grey[70] }}
          >
            You can create your own service by using our Cochl.Sense Cloud API
            or Edge SDK.
          </Typo>
          <LabelContainer style={{ marginTop: 0 }}>
            <Typo variant="h4">Project name</Typo>
          </LabelContainer>
          <InputWithWarning>
            <CochlSenseProjectInput
              placeholder={`Input at least 1 characters, with a maximum of ${PROJECT_NAME_LIMIT} characters`}
              value={name}
              onChange={onChangeInput}
              validated={
                validation.validating ? validation.validated : undefined
              }
              error={
                validation.validating && !!validation.error
                  ? validation.error
                  : undefined
              }
              autoFocus
            />
          </InputWithWarning>
          <LabelContainer>
            <Typo variant="h4">Project type</Typo>
          </LabelContainer>
          <ProjectTypeWrapper>
            <TypeSelectButton
              text={PRODUCT_TYPE_UI_LABELS[CLOUD_API]}
              selected={projectType === CLOUD_API}
              onClick={() => selectProjectType(CLOUD_API)}
              disabled={!isAvailableAPI}
              icon={<CloudAPIStyledIcon selected={projectType === CLOUD_API} />}
            />
            <TypeSelectButton
              text={PRODUCT_TYPE_UI_LABELS[EDGE_SDK]}
              selected={projectType === EDGE_SDK}
              onClick={() => selectProjectType(EDGE_SDK)}
              disabled={!isAvailableSDK}
              icon={<EdgeSDKStyledIcon selected={projectType === EDGE_SDK} />}
            />
          </ProjectTypeWrapper>
        </Flex>
        {isAvailableSDK && projectType === ProjectType.EDGE_SDK && (
          <>
            <Divider style={{ margin: "0 20px" }} />
            <SDKTagContainer>
              <Flex style={{ flexDirection: "column" }}>
                <SDKTagInfo>
                  <Flex style={{ alignItems: "center" }}>
                    <Typo variant="h4">Select tags</Typo>
                    <TagsCountBadge>
                      <Typo variant="caption3">{tags.length}</Typo>
                    </TagsCountBadge>
                    <Divider style={{ height: "14px" }} />
                    <TagsPrice variant="body">
                      {numberWithCommas({ value: tagPrice, currency: "usd" })}
                    </TagsPrice>
                  </Flex>
                  <Checkbox
                    selected={isSelectAll}
                    onChange={onClickSelectAll}
                    label="Select all"
                  />
                </SDKTagInfo>
              </Flex>
              <TagCategory
                tags={categoryTags}
                onClickItem={onClickTags}
                selectedItems={tags}
                style={{ maxHeight: "240px" }}
                selectedCategory={selectedTagCategory}
                onClickCategory={setSelectedTagCategory}
              />
            </SDKTagContainer>
          </>
        )}
      </Inner>
      <ConfirmButton
        onClick={onClickConfirm}
        disabled={
          (validation.validating && !!validation.error) ||
          !projectType ||
          !name ||
          (projectType === ProjectType.EDGE_SDK && tags.length === 0)
        }
        color="primary"
      >
        {t("Create Project", { ns: "common" })}
      </ConfirmButton>
    </ModalContainer>
  );
};

export default memo(ProjectCreateModal);

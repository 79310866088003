import { FileInfo, UploadStatus } from "~/client/types/upload";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SenseEvent, SenseUniqueTag } from "~/client/types/senseAPI";

import { Transcription } from "../../types";

interface Upload {
  projectId: string;
  status?: UploadStatus;
  fileInfo?: FileInfo;
  sessionId?: string;
  sessionResult?: {
    result: SenseEvent[];
    uniqueTag: SenseUniqueTag[];
  };
  transcription?: Transcription[];
  uploadProgress?: number;
}

interface UploadState {
  uploadInfo: Upload[];
  withSR: boolean;
}

const initialState: UploadState = {
  uploadInfo: [],
  withSR: false,
};

const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    setUploadInfo: (state, action: PayloadAction<Upload>) => {
      const {
        projectId,
        status,
        fileInfo,
        sessionId,
        sessionResult,
        transcription,
        uploadProgress,
      } = action.payload;

      const foundIdx = state.uploadInfo.findIndex(
        (data) => data.projectId === projectId,
      );
      const newInfo = {
        projectId,
        ...(status && { status }),
        ...(fileInfo && { fileInfo }),
        ...(sessionId && { sessionId }),
        ...(sessionResult && { sessionResult }),
        ...(transcription && { transcription }),
        ...(typeof uploadProgress === "number" && { uploadProgress }),
      };
      if (foundIdx === -1) {
        state.uploadInfo = [...state.uploadInfo, newInfo];
      } else {
        state.uploadInfo[foundIdx] = {
          ...state.uploadInfo[foundIdx],
          ...newInfo,
        };
      }
    },
    resetUploadInfo: (state, action: PayloadAction<{ projectId: string }>) => {
      const { projectId } = action.payload;
      const foundIdx = state.uploadInfo.findIndex(
        (data) => data.projectId === projectId,
      );
      if (foundIdx !== -1) {
        state.uploadInfo = state.uploadInfo.filter(
          (data) => data.projectId !== projectId,
        );
      }
    },
    setWithSR: (state, action: PayloadAction<boolean>) => {
      state.withSR = action.payload;
    },
  },
  extraReducers: () => {
    //
  },
});

export const { setUploadInfo, resetUploadInfo, setWithSR } =
  uploadSlice.actions;
export const { reducer: uploadReducer } = uploadSlice;

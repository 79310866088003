import {
  PAGE_ROUTE_PATH_NAME,
  PageRoute,
  SubTab,
} from "~/client/types/pageRoute";
import { convertProjectDetailPath, selectedPageRouteState } from "~/client/lib";
import { matchPath, useLocation, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  useCurrentProject,
  useProjectList,
} from "~/client/hooks";
/* eslint-disable no-case-declarations */
import { useCallback, useEffect, useMemo, useState } from "react";

import { MATCHED_PATH } from "../constants";
import { ProjectType } from "~/client/types/project";
import { changeCurrentRoute } from "~/client/store/modules";
import checkAppVersion from "~/client/lib/helpers/checkAppVersion";
import { pageView } from "../ga/utils";
import routes from "~/client/lib/routes";
import { useError } from "~/client/lib/hooks";
import { useTranslation } from "react-i18next";

function usePageRoute(): {
  currentRoute: PageRoute | null;
  subTabs: SubTab[];
  subheaderTitle: string;
  projectKey: string;
  shareInfo?: { text: string; color: string };
  bannerInfo?: { text: string; link?: string };
} {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = useParams<{ projectId?: string }>();
  const { organizationOwnerList } = useProjectList();
  const { currentProject } = useCurrentProject();
  const { initializeError } = useError();
  const { currentPageRoute: currentRoute } = useAppSelector(
    selectedPageRouteState,
  );
  const [bannerInfo, setBannerInfo] = useState<{
    text: string;
    link?: string;
  }>();
  const [subTabs, setSubTabs] = useState<SubTab[]>([]);
  const [subheaderTitle, setSubheaderTitle] = useState<string>("");
  const [projectKey, setProjectKey] = useState<string>("");
  const [shareInfo, setShareInfo] = useState<{ text: string; color: string }>();
  const ownerInfo = useMemo(
    () =>
      organizationOwnerList?.find(
        (owner) => owner.organization_id === currentProject?.organization_id,
      ),
    [currentProject?.organization_id, organizationOwnerList],
  );

  const initializeAllError = useCallback(() => {
    initializeError();
    // todo: add functions to initialize other modules' error
  }, [initializeError]);

  useEffect(() => {
    if (!currentRoute) return;
    const { title, pathname } = currentRoute;
    if (!pathname || !title) return;
    if (pathname.includes("/:projectId")) {
      setSubheaderTitle(currentProject?.name ?? "");
      setProjectKey(currentProject?.key ?? "");
      ownerInfo &&
        setShareInfo({
          text: ownerInfo.full_name ? ownerInfo.full_name[0] : "",
          color: ownerInfo.color,
        });
      if (currentProject?.product_type === ProjectType.CLOUD_API) {
        setBannerInfo({
          text: "Speaker verification BETA is now live for Cloud API projects",
          link: `/project/${currentProject?.id}/speaker_recognition`,
        });
      }
    } else {
      setSubheaderTitle(title);
      setShareInfo(undefined);
    }
  }, [currentRoute, params.projectId, currentProject, ownerInfo]);

  useEffect(() => {
    initializeAllError();
  }, [location, initializeAllError]);

  useEffect(() => {
    const { pathname } = location;
    pageView(pathname);
  }, [location]);

  useEffect(() => {
    const matchedPageItem = routes.find((item) => {
      const matched = matchPath(location.pathname, {
        path: item.pathname,
        exact: true,
      });
      return matched && matched.path === item.pathname;
    });
    dispatch(
      changeCurrentRoute(
        matchedPageItem
          ? {
              title: matchedPageItem.title,
              pathname: matchedPageItem.pathname,
              exact: matchedPageItem.exact,
            }
          : null,
      ),
    );
    checkAppVersion();
  }, [dispatch, location]);

  useEffect(() => {
    ownerInfo &&
      setShareInfo({
        text: ownerInfo.full_name ? ownerInfo.full_name[0] : "",
        color: ownerInfo.color,
      });
  }, [ownerInfo]);

  useEffect(() => {
    const matched = matchPath<{ projectId?: string }>(location.pathname, {
      path: MATCHED_PATH,
    });

    if (!matched) {
      setSubTabs([]);
      return;
    }
    const { params, path } = matched;
    switch (path) {
      case PAGE_ROUTE_PATH_NAME.USAGE:
      case PAGE_ROUTE_PATH_NAME.USAGE_API:
      case PAGE_ROUTE_PATH_NAME.USAGE_SDK:
        setSubTabs([
          {
            to: PAGE_ROUTE_PATH_NAME.USAGE_API,
            label: t("Cloud API", { ns: "common" }),
            selected: currentRoute?.pathname === PAGE_ROUTE_PATH_NAME.USAGE_API,
          },
          {
            to: PAGE_ROUTE_PATH_NAME.USAGE_SDK,
            label: t("Edge SDK", { ns: "common" }),
            selected: currentRoute?.pathname === PAGE_ROUTE_PATH_NAME.USAGE_SDK,
          },
        ]);
        break;
      case PAGE_ROUTE_PATH_NAME.BILLING:
      case PAGE_ROUTE_PATH_NAME.BILLING_INFO:
      case PAGE_ROUTE_PATH_NAME.BILLING_INVOICES:
        setSubTabs([
          {
            to: PAGE_ROUTE_PATH_NAME.BILLING_INFO,
            label: t("Information", { ns: "common" }),
            selected:
              currentRoute?.pathname === PAGE_ROUTE_PATH_NAME.BILLING_INFO,
          },
          {
            to: PAGE_ROUTE_PATH_NAME.BILLING_INVOICES,
            label: t("Invoices", { ns: "common" }),
            selected:
              currentRoute?.pathname === PAGE_ROUTE_PATH_NAME.BILLING_INVOICES,
          },
        ]);
        break;
      case PAGE_ROUTE_PATH_NAME.ORGANIZATION:
      case PAGE_ROUTE_PATH_NAME.ORGANIZATION_MY_MEMBERS:
      case PAGE_ROUTE_PATH_NAME.ORGANIZATION_INVITED:
        setSubTabs([
          {
            to: PAGE_ROUTE_PATH_NAME.ORGANIZATION_MY_MEMBERS,
            label: t("My members", { ns: "common" }),
            selected:
              currentRoute?.pathname ===
              PAGE_ROUTE_PATH_NAME.ORGANIZATION_MY_MEMBERS,
          },
          {
            to: PAGE_ROUTE_PATH_NAME.ORGANIZATION_INVITED,
            label: t("Joined", { ns: "common" }),
            selected:
              currentRoute?.pathname ===
              PAGE_ROUTE_PATH_NAME.ORGANIZATION_INVITED,
          },
        ]);
        break;
      case PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL:
      case PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_SENSE_SDK:
      case PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_ANALYTICS:
      case PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_ANALYTICS_OLD:
      case PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_SETTINGS:
      case PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_UPLOAD:
      case PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_POST_ACTION:
      case PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_SPEAKER_RECOGNITION:
        // eslint-disable-next-line no-case-declarations
        const { projectId } = params;
        if (!projectId) {
          break;
        }

        setSubTabs([
          ...(currentProject?.product_type === ProjectType.CLOUD_API
            ? [
                {
                  to: convertProjectDetailPath(
                    PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_UPLOAD,
                    projectId,
                  ),
                  label: t("Upload", { ns: "common" }),
                  selected:
                    currentRoute?.pathname ===
                    PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_UPLOAD,
                  disabled: !!ownerInfo,
                  isBETA: true,
                },
              ]
            : []),
          {
            to: convertProjectDetailPath(
              PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL,
              projectId,
            ),
            label: t("Overview", { ns: "common" }),
            selected:
              currentRoute?.pathname === PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL,
            disabled: !!ownerInfo,
          },
          ...(currentProject?.product_type === ProjectType.CLOUD_API
            ? [
                {
                  to: convertProjectDetailPath(
                    PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_SPEAKER_RECOGNITION,
                    projectId,
                  ),
                  label: t("Speaker Recognition", { ns: "common" }),
                  selected:
                    currentRoute?.pathname ===
                    PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_SPEAKER_RECOGNITION,
                  disabled: !!ownerInfo,
                  isBETA: true,
                },
              ]
            : []),
          ...(currentProject?.product_type === ProjectType.EDGE_SDK
            ? [
                {
                  to: convertProjectDetailPath(
                    PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_SENSE_SDK,
                    projectId,
                  ),
                  label: t("Edge SDK", { ns: "common" }),
                  selected:
                    currentRoute?.pathname ===
                    PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_SENSE_SDK,
                  disabled: !!ownerInfo,
                },
              ]
            : []),
          {
            to: convertProjectDetailPath(
              PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_ANALYTICS,
              projectId,
            ),
            label: t("Analytics", { ns: "common" }),
            selected:
              currentRoute?.pathname ===
              PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_ANALYTICS,
          },
          {
            to: convertProjectDetailPath(
              PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_ANALYTICS_OLD,
              projectId,
            ),
            label: "History",
            selected:
              currentRoute?.pathname ===
              PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_ANALYTICS_OLD,
          },
          {
            to: convertProjectDetailPath(
              PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_POST_ACTION,
              projectId,
            ),
            label: t("Post Actions", { ns: "common" }),
            selected:
              currentRoute?.pathname ===
              PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_POST_ACTION,
            disabled: !!ownerInfo,
          },
          {
            to: convertProjectDetailPath(
              PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_SETTINGS,
              projectId,
            ),
            label: t("Settings", { ns: "common" }),
            selected:
              currentRoute?.pathname ===
              PAGE_ROUTE_PATH_NAME.PROJECT_DETAIL_SETTINGS,
            disabled: !!ownerInfo,
          },
        ]);
        break;
      default:
        setSubTabs([]);
        break;
    }
  }, [currentRoute, location, t, currentProject?.product_type, ownerInfo]);

  return {
    currentRoute,
    subTabs,
    subheaderTitle,
    projectKey,
    shareInfo,
    bannerInfo,
  };
}

export default usePageRoute;

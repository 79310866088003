import { Banner, HyperlinkButton, Typo } from "@cochlearai/ui";
import { Footer, Header, HeaderSidebar, SubHeader } from "~/client/components";
import { PropsWithChildren, Suspense, useMemo, useState } from "react";

import { HeaderMenuItemType } from "~/client/types/header";
import { PAGE_ROUTE_PATH_NAME } from "~/client/types";
import moment from "moment";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
`;

const Main = styled.main`
  flex: 1;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
`;

const PageTemplate = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [headerSidebarVisible, setHeaderSidebarVisible] =
    useState<boolean>(false);
  const [bannerVisible, setBannerVisible] = useState<boolean>(true);

  const menus: HeaderMenuItemType[] = useMemo(
    () => [
      {
        to: PAGE_ROUTE_PATH_NAME.PROJECT,
        selected: location.pathname === PAGE_ROUTE_PATH_NAME.PROJECT,
        label: t("Projects", { ns: "common" }),
      },
      {
        to: PAGE_ROUTE_PATH_NAME.USAGE,
        selected: location.pathname === PAGE_ROUTE_PATH_NAME.USAGE,
        label: t("Usage", { ns: "common" }),
      },
      {
        to: PAGE_ROUTE_PATH_NAME.BILLING,
        selected: location.pathname.startsWith(PAGE_ROUTE_PATH_NAME.BILLING),
        label: t("Billing", { ns: "common" }),
      },
      {
        to: PAGE_ROUTE_PATH_NAME.ORGANIZATION,
        selected: location.pathname.startsWith(
          PAGE_ROUTE_PATH_NAME.ORGANIZATION,
        ),
        label: t("Organization", { ns: "common" }),
      },
    ],
    [t, location],
  );

  return (
    <>
      <Root>
        <Header
          menus={menus}
          onClickSideBarMenu={() => setHeaderSidebarVisible(true)}
        />
        {location &&
          location.pathname === PAGE_ROUTE_PATH_NAME.PROJECT &&
          bannerVisible && (
            <Banner onClose={() => setBannerVisible(false)}>
              <Typo variant="body">
                Speaker Recognition BETA is now live for Cloud API projects
              </Typo>
            </Banner>
          )}
        {location && location.pathname !== PAGE_ROUTE_PATH_NAME.PROJECT && (
          <SubHeader />
        )}
        <Main>{children}</Main>
        <Footer
          copyrightText={`© ${moment(new Date()).format("YYYY")} Cochl Inc`}
        />
      </Root>
      <Suspense fallback="">
        <HeaderSidebar
          menus={menus}
          visible={headerSidebarVisible}
          onClose={() => setHeaderSidebarVisible(false)}
        />
      </Suspense>
    </>
  );
};

export default PageTemplate;

import {
  FetchCreateTrackingGroupParams,
  FetchCreateTrackingGroupResponse,
  FetchDeleteTrackingGroupParams,
  FetchDeleteTrackingGroupResponse,
  FetchGetAPITopTagsParams,
  FetchGetAPITopTagsResponse,
  FetchGetAnalyticsEventParams,
  FetchGetAnalyticsEventResponse,
  FetchGetHeatmapEventsParams,
  FetchGetHeatmapEventsResponse,
  FetchGetParametersResponse,
  FetchGetParamtersParams,
  FetchGetSDKTopTagsParams,
  FetchGetSDKTopTagsResponse,
  FetchGetTimeseriesParams,
  FetchGetTimeseriesResponse,
  FetchGetTrackingGroupParams,
  FetchGetTrackingGroupResponse,
} from "~/client/types/api/analytics";

import { APICallPayloads } from "~/client/types/api";
import { getConfigs } from "~/client/lib";
import { makeAPIRequest } from "~/client/lib/api";

const { REACT_APP_ANALYTICS_API_BASE_URL: analyticsApiBaseURL } = getConfigs();

const API_URL_PREFIX = analyticsApiBaseURL ? `${analyticsApiBaseURL}` : "";

// New Analytics

export const fetchGetHeatmapEvents = ({
  params,
  config,
}: APICallPayloads<FetchGetHeatmapEventsParams>): Promise<FetchGetHeatmapEventsResponse> => {
  const { project_id, from, to, scale, tags, devices } = params;
  let url = `${API_URL_PREFIX}/projects/${project_id}/heatmap?&`;

  if (tags && tags.length > 0) {
    url += `tags=${tags.join(",")}&`;
  }
  if (devices && devices.length > 0) {
    url += `devices=${devices.join(",")}&`;
  }
  if (scale) {
    url += `scale=${scale}&`;
  }
  if (from) {
    url += `from=${encodeURIComponent(from)}&`;
  }
  if (to) {
    url += `to=${encodeURIComponent(to)}&`;
  }

  return makeAPIRequest({
    ...config,
    url: url.split(";").join("%3B"),
    method: "GET",
  });
};

export const fetchGetTimeseries = ({
  params,
  config,
}: APICallPayloads<FetchGetTimeseriesParams>): Promise<FetchGetTimeseriesResponse> => {
  const {
    project_id,
    from,
    to,
    scale,
    tags,
    devices,
    tags_merge,
    devices_merge,
  } = params;
  let url = `${API_URL_PREFIX}/projects/${project_id}/timeseries?&`;

  if (tags && tags.length > 0) {
    url += `tags=${tags.join(",")}&`;
  }
  if (devices && devices.length > 0) {
    url += `devices=${devices.join(",")}&`;
  }
  if (scale) {
    url += `scale=${scale}&`;
  }
  if (from) {
    url += `from=${encodeURIComponent(from)}&`;
  }
  if (to) {
    url += `to=${encodeURIComponent(to)}&`;
  }
  if (tags_merge) {
    url += `tags_merge=${tags_merge}&`;
  }
  if (devices_merge) {
    url += `devices_merge=${devices_merge}&`;
  }

  return makeAPIRequest({
    ...config,
    url: url.split(";").join("%3B"),
    method: "GET",
  });
};

export const fetchGetSDKTopTags = ({
  params,
  config,
}: APICallPayloads<FetchGetSDKTopTagsParams>): Promise<FetchGetSDKTopTagsResponse> => {
  const { project_id, from, to, tags, devices } = params;
  let url = `${API_URL_PREFIX}/projects/${project_id}/top-tags-breakdown?&`;

  if (tags && tags.length > 0) {
    url += `tags=${tags.join(",")}&`;
  }
  if (devices && devices.length > 0) {
    url += `devices=${devices.join(",")}&`;
  }
  if (from) {
    url += `from=${encodeURIComponent(from)}&`;
  }
  if (to) {
    url += `to=${encodeURIComponent(to)}&`;
  }
  return makeAPIRequest({
    ...config,
    url: url.split(";").join("%3B"),
    method: "GET",
  });
};

export const fetchGetAPITopTags = ({
  params,
  config,
}: APICallPayloads<FetchGetAPITopTagsParams>): Promise<FetchGetAPITopTagsResponse> => {
  const { project_id, session_ids, tags } = params;
  let url = `${API_URL_PREFIX}/projects/${project_id}/top-tags?&`;

  if (tags && tags.length > 0) {
    url += `tags=${tags.join(",")}&`;
  }
  if (session_ids && session_ids.length > 0) {
    url += `session_ids=${session_ids.join(",")}&`;
  }
  return makeAPIRequest({
    ...config,
    url: url.split(";").join("%3B"),
    method: "GET",
  });
};

export const fetchGetTrackingGroup = ({
  params,
  config,
}: APICallPayloads<FetchGetTrackingGroupParams>): Promise<FetchGetTrackingGroupResponse> => {
  const url = `${API_URL_PREFIX}/projects/${params.project_id}/tracking-group`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};

export const fetchCreateTrackingGroup = ({
  params,
  config,
}: APICallPayloads<FetchCreateTrackingGroupParams>): Promise<FetchCreateTrackingGroupResponse> => {
  const url = `${API_URL_PREFIX}/projects/${params.project_id}/tracking-group`;
  return makeAPIRequest({
    ...config,
    url,
    method: "POST",
    data: { tags: params.tags },
  });
};

export const fetchRemoveTrackingGroup = ({
  params,
  config,
}: APICallPayloads<FetchDeleteTrackingGroupParams>): Promise<FetchDeleteTrackingGroupResponse> => {
  const url = `${API_URL_PREFIX}/projects/${params.project_id}/tracking-group`;
  return makeAPIRequest({
    ...config,
    url,
    method: "DELETE",
  });
};

// previous analytics

/**
 * @see Docs https://api.beta.cochl.ai/analytics/docs/#tag/Browser/operation/get_events
 */
export const fetchGetAnalyticsEvents = ({
  config,
  params,
}: APICallPayloads<FetchGetAnalyticsEventParams>): Promise<FetchGetAnalyticsEventResponse> => {
  const {
    session_id,
    channel_id,
    project_id,
    filter: { group, io_type, content_type, tag_name, platform, device_id },
    from,
    to,
    limit,
    page,
    sort,
  } = params;
  let url = `${API_URL_PREFIX}/events?&`;
  let filter = "";

  if (session_id) {
    url += `session_ids=${session_id}&`;
  }
  if (project_id.length > 0) {
    project_id.forEach((item) => {
      url += `project_ids=${item}&`;
    });
  }
  if (channel_id) {
    filter += `channel_id:${channel_id}`;
  }
  if (group.length > 0) {
    group.forEach((item) => {
      filter += `${filter ? "," : ""}group:${item}`;
    });
  }
  if (io_type.length > 0) {
    io_type.forEach((item) => {
      filter += `${filter ? "," : ""}io_type:${item}`;
    });
  }
  if (content_type.length > 0) {
    content_type.forEach((item) => {
      filter += `${filter ? "," : ""}content_type:${item}`;
    });
  }
  if (tag_name.length > 0) {
    tag_name.forEach((item) => {
      filter += `${filter ? "," : ""}tag_name:${item}`;
    });
  }
  if (device_id && device_id.length > 0) {
    device_id.forEach((item) => {
      filter += `${filter ? "," : ""}device_id:${item}`;
    });
  }
  if (filter) {
    url += `filter=${filter}&`;
  }
  if (from) {
    url += `from=${from}&`;
  }
  if (to) {
    url += `to=${to}&`;
  }
  if (limit) {
    url += `limit=${limit}&`;
  }
  if (page) {
    url += `page=${page}&`;
  }
  if (sort) {
    url += `sort=${sort}&`;
  }
  return makeAPIRequest({
    ...config,
    url: url.split(";").join("%3B"),
    method: "GET",
  });
};

export const fetchGetParameters = ({
  config,
}: APICallPayloads<FetchGetParamtersParams>): Promise<FetchGetParametersResponse> => {
  const url = `${API_URL_PREFIX}/parameters`;
  return makeAPIRequest({
    ...config,
    url,
    method: "GET",
  });
};
